import Vue from "vue";
import Vuex from "vuex";
import Snotify from "vue-snotify";

Vue.use(Snotify);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    overlay : false
  },
  mutations: {

  },
  actions: {

  },
  modules: {}
});