export const backend = {
  url: process.env.NODE_ENV === "production" ?
    "https://saludalamano.com.mx/apiServiciosChedraui/" : "https://saludalamano.com.mx/apiServiciosChedraui/",
  urlProyecto: process.env.NODE_ENV === "production" ?
    "" : "",
  headers: {
    "Content-Type": "application/json",
    apiKey: "S9BucUM0EXP6g9o",
  },
  secretKey: "ingDLMRuGe9UKHRNjs7cYckS2yul4lc3",
  urlNotify: "https://saludalamano.com.mx/apiServiciosChedraui/notificacion/pago",
  usuBw: process.env.NODE_ENV === "production" ?
    "enlacemujer" : "pruebasbw",
  sandbox: process.env.NODE_ENV === "production" ?
    false : true,
};