<template>
<v-app>
    <v-overlay :value="$store.state.overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <vue-snotify />
    <router-view />
</v-app>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style src='@/assets/css/estilos.css'></style>
