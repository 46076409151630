import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueJWT from 'vuejs-jwt';
import Snotify from "vue-snotify";
import VueLocalStorage from 'vue-localstorage';
import VueMask from 'v-mask';
import {
  backend
} from '@/back/global.js';

import "roboto-fontface/css/roboto/roboto-fontface.css";

Vue.config.productionTip = false;
Vue.use(VueJWT, {signKey:backend.secretKey});
Vue.use(Snotify);
Vue.use(VueLocalStorage);
Vue.use(VueMask);

new Vue({
  VueMask,
  VueLocalStorage,
  Snotify,
  VueJWT,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
