import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/assists",
    name: "Assists",
    component: () => import("@/views/assit/Informacion.vue")
  },
  {
    path: "/resumenassist",
    name: "Resumenassist",
    component: () => import("@/views/assit/Resumen.vue")
  }, {
    path: "/contrataassist",
    name: "Contrataassist",
    component: () => import("@/views/assit/Contrataassit.vue")
  }, {
    path: "/clienteassit",
    name: "Clienteassit",
    component: () => import("@/views/assit/Clienteassit.vue")
  },
  {
    path: "/contratadespensa",
    name: "Contratadespensa",
    component: () => import("@/views/despensa/Contratadespensa.vue")
  },
  {
    path: "/clientedespensa",
    name: "Clientedespensa",
    component: () => import("@/views/despensa/Clientedespensa.vue")
  },
  {
    path: "/pago",
    name: "Pago",
    component: () => import("@/views/pago/Pago.vue")
  },
  {
    path: "/compradespensa",
    name: "Compradespensa",
    component: () => import("@/views/despensa/Compradespensa.vue")
  },
  {
    path: "/miseguro",
    name: "MiSeguro",
    component: () => import("@/views/MiSeguro.vue")
  },
  {
    path: "/despensa",
    name: "Despensa",
    component: () => import("@/views/despensa/Despensa.vue")
  },
  {
    path: "/remesa",
    name: "Remesa",
    component: () => import("@/views/Remesa.vue")
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue")
  }
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/" : "/",
  routes
});

export default router;